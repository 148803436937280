<template>
  <div class="product-page">
    <h1 class="page-title">{{ $t('Apps') }}</h1>
    <ul class="list-app mb-6">
      <li v-for="item in apps" :key="item.id">
        <v-card class="pa-6 h-100 d-flex align-start" style="gap: 16px;" @click="gotoApp(item.route)">
          <v-icon :color="item.color">{{ item.icon }}</v-icon>
          <div class="flex-1">
            <h3>{{ item.name }}</h3>
            <p>{{ item.desc }}</p>
            <div class="d-flex align-center" style="gap: 4px">
              <v-chip v-for="att in item.attr" :key="att" small class="rounded-lg">
                {{ att }}
              </v-chip>
            </div>
          </div>
        </v-card>
      </li>
    </ul>
    <p>
      Working with a developer on your shop?
      <span class="private-app" @click="gotoPrivateApp">Manage private apps.</span>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      apps: [
        {
          id: 1,
          name: 'Boost Upsell',
          route: 'website.apps.upsell.list',
          desc: 'Increase Order value & Sales with Smart Upselling',
          icon: 'mdi-basket',
          attr: ['Most Popular', 'UpSell'],
          color: 'error',
        },
        {
          id: 2,
          name: 'Product Review',
          route: 'website.apps.product-review.list',
          icon: 'mdi-chat-processing',
          desc: 'Build trust for your products and store through reviews from your customers.',
          attr: ['Review', 'Free'],
          color: 'warning',
        },
        {
          id: 3,
          name: 'Ali Dropship Connector',
          route: 'website.apps.ali-connector.import',
          icon: 'mdi-store',
          desc: 'Import and sync AliExpress dropshipping products in one-click.',
          attr: ['Fulfillment', 'Dropshipping'],
          color: 'success',
        },
        // {
        //   id: 4,
        //   name: 'Print On Demand Products',
        //   route: 'website.apps.print-hub.catalog',
        //   desc: 'Make Print-On-Demand easier and more profitable than ever',
        //   image: 'https://minio.lattehub.com/lattehub/static/20220217/4.png',
        //   attr: ['Free'],
        // },
        {
          id: 5,
          name: 'Migrate To Dcomcy',
          route: 'website.apps-migrate',
          desc: 'Migrate to Dcomcy now',
          icon: 'mdi-toolbox',
          attr: ['Free', 'Dcomcy'],
          color: 'info',
        },
      ],
    };
  },
  methods: {
    gotoApp(name) {
      this.$router.push({
        name: name,
      });
      this.$store.commit('setNavigationMini', true);
    },
    gotoPrivateApp() {
      this.$router.push({ name: 'website.apps-private' });
      this.$store.commit('setNavigationMini', false);
    },
  },
};
</script>
<style lang="scss" scoped>
ul.list-app {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  padding: 0;
  list-style: none;
  gap: 16px;
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 24px;
  }
  li .v-card {
    height: 100%;
    i.v-icon {
      font-size: 44px;
    }
  }
}
.private-app {
  color: #0091eb;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
